<script>
// import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
// import PageHeader from "@/components/page-header";
// import axios from "axios";
// import Swal from "sweetalert2";
import Datatable from "@/router/layouts/table-datatable.vue";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    Datatable,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Default",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      columns: [
        { width: "50px", label: "No", name: "-" },
        { width: "auto", label: "Soal", name: "-" },
        { width: "auto", label: "Skor", name: "-" },
        { width: "auto", label: "Status", name: "-" },
        { width: "auto", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
      ],
      dataTablePenilaian: [
        { width: "50px", label: "No", name: "-" },
        { width: "auto", label: "Keyword", name: "-" },
        { width: "auto", label: "Bobot", name: "-" },
        { width: "auto", label: "Skor", name: "-" },
      ],
      modalHistory: false,
    };
  },
};
</script>

<template>
  <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
  <div class="container">
    <div class="row mt-3">
      <div class="col-xl-6 font-size-18 mt-1">
        ASESMEN HC - UNIT PELAKSANAAN SERTIFIKASI
      </div>
      <div class="col-xl-6 text-end">
        <button type="button" class="btn btn-danger">Logout</button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header bg-primary text-white font-size-16">
            List Soal
          </div>
          <div class="card-body font-size-14">
            <div class="card mb-2">
              <div class="card-body shadow">
                <div class="row">
                  <div class="col-xl-3">Kompetensi</div>
                  <div class="col-xl-3">: -</div>
                </div>
                <div class="row">
                  <div class="col-xl-3">Level</div>
                  <div class="col-xl-3">: -</div>
                </div>
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-body shadow">
                <div class="row">
                  <div class="col-xl-3">Nama</div>
                  <div class="col-xl-3">: -</div>
                </div>
                <div class="row">
                  <div class="col-xl-3">NIP</div>
                  <div class="col-xl-3">: -</div>
                </div>
                <div class="row">
                  <div class="col-xl-3">Jabatan</div>
                  <div class="col-xl-3">: -</div>
                </div>
              </div>
            </div>
            <datatable :columns="columns">
              <tbody>
                <tr>
                  <td>1.</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-center">
                    <button type="button" class="btn btn-primary">Pilih</button>
                  </td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-center">
                    <button type="button" class="btn btn-primary">Pilih</button>
                  </td>
                </tr>
              </tbody>
              <tfoot class="bg-dark text-white">
                <tr>
                  <td></td>
                  <td>Total Nilai</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr></tfoot
            ></datatable>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg-primary text-white font-size-16">
            Jawaban Peserta
          </div>
          <div class="card-body font-size-14">
            <div class="card shadow mb-3">
              <div class="card-header bg-dark text-white">Soal</div>
              <div class="card-body" >
                <div class="bg-light p-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Minima optio perspiciatis dignissimos architecto, non
                  praesentium quo ducimus eum veritatis aliquid quia quasi cum,
                  impedit nulla adipisci omnis corporis ipsum odio!
                </div>
              </div>
            </div>
            <div class="card shadow mb-3">
              <div class="card-header bg-dark text-white">Jawaban Peserta</div>
              <div class="card-body border">
                <div class="bg-light p-2 mb-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Minima optio perspiciatis dignissimos architecto, non
                  praesentium quo ducimus eum veritatis aliquid quia quasi cum,
                  impedit nulla adipisci omnis corporis ipsum odio!
                </div>
                <button type="button" class="btn btn-primary float-end">
                  Detail
                </button>
              </div>
            </div>
            <div class="card shadow mb-0">
              <div class="card-header bg-dark text-white">Penilaian</div>
              <div class="card-body border">
                <datatable :columns="dataTablePenilaian">
                  <tbody>
                    <tr>
                      <td>1.</td>
                      <td></td>
                      <td></td>
                      <td>
                        <b-form-input type="number" required></b-form-input>
                      </td>
                    </tr>
                    <tr>
                      <td>2.</td>
                      <td></td>
                      <td></td>
                      <td>
                        <b-form-input type="number" required></b-form-input>
                      </td>
                    </tr>
                  </tbody>
                </datatable>
                <button type="button" class="btn btn-primary float-end mt-2">
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 text-end mb-3">
            <button type="button" class="btn btn-primary">Kembali</button>
            &nbsp;
            <button type="button" class="btn btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
  <!-- </Layout> -->
</template>
<style scoped>
.shadow {
    box-shadow: 2px 2px 5px 0px silver !important;
}
</style>